
import React, { useState, useEffect } from 'react'
import Map from './Map'


const App = () => {

    return (
        <div><Map /></div>

    )
}

export default App